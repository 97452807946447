import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";

export default ({drinks}) => (
  <Grid container spacing={2}>
    {drinks.map((drink, i) => (
      <Grid item xs={12} key={i}>
        <Card>
          <CardMedia
            component="img"
            height="280"
            alt={drink.name}
            image={drink.image}
          ></CardMedia>
          <CardHeader title={drink.name} />
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {drink.ingredients.join(", ")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);
