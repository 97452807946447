export const Halloween = [
  {
    name: "Haunted Graveyard",
    ingredients: ["Bourbon", "Maple Syrup", "Orange", "Bitters", "Rosemary"],
    image:
      "https://www.sprinklesandsprouts.com/wp-content/uploads/2018/09/Haunted-Graveyard-Halloween-Cocktail.jpg",
  },
  {
    name: "Sparkling Cider and Bourbon",
    ingredients: ["Sparkling Cider", "Bourbon", "Bitters", "Apple"],
    image:
      "https://hips.hearstapps.com/hmg-prod/images/sparkling-cider-and-bourbon-cocktail-1661455951.jpg?crop=1.00xw:0.834xh;0,0.0704xh&resize=1200:*",
  }
];

export default Halloween;
