import React from "react";
import { AppBar, Container, CssBaseline , Toolbar, Typography} from "@mui/material";
import TabMenu from "./TabMenu";

export default function App() {
  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CJ's Bar Menu
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <TabMenu />
      </Container>
    </div>
  );
}
