export const cocktails = [
  {
    name: "Old Fashioned",
    ingredients: [
      "Smoked Burbon Whiskey",
      "Brown Sugar",
      "Bitters",
      "Orange Peel",
      "Maraschino Cherry",
    ],
    image:
      "https://assets.epicurious.com/photos/5e41a6d175661800087cc87c/5:4/w_3923,h_3138,c_limit/OldFashioned_HERO_020520_619.jpg",
  },
  {
    name: "007",
    ingredients: ["Gin", "Vodka", "Lillet"],
    image:
      "https://media.30seconds.com/tip/lg/How-to-Make-a-Classic-Martini-Shaken-or-Stirred-14113-6c12b5ee69-1496255777.jpg",
  },
  {
    name: "Margarita",
    ingredients: ["Tequilla", "Triple Sec", "Lime Juice", "Agave"],
    image:
      "https://www.seriouseats.com/thmb/PIVqgfUtdn74p6KurPXlrNwlxKs=/1125x1125/smart/filters:no_upscale()/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__recipes__images__2015__04__20150323-cocktails-vicky-wasik-margarita-c84b154e757d43688de15dc8f8ca0de9.jpg",
  },
  {
    name: "LaFleurADora",
    ingredients: ["Gin", "Lime Juice", "Rasberry Liquor", "Ginger Beer"],
    image: "/images/lafluer.png",
  },
  {
    name: "Bloody Mary",
    ingredients: [
      "Vodka or Mescal",
      "Tomato Juice",
      "Lime Juice",
      "Celery Salt",
      "Pepper",
      "Hot Sauce",
      "Worcestershire Sauce",
    ],
    image:
      "https://images.immediate.co.uk/production/volatile/sites/30/2020/08/bloody-mary-glass-2258f4e.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,458",
  },
  {
    name: "Moscow Mule",
    ingredients: ["Vodka", "Ginger Beer", "Lime Juice"],
    image:
      "https://www.thespruceeats.com/thmb/XJeTzWlEoWXXRO1_wtYP-jYKSHE=/3845x3845/smart/filters:no_upscale()/moscow-mule-recipe-759741-hero-01-8745d4ade7a94c4bbe0542fe0ab9701e.jpg",
  },
  {
    name: "Whiskey Sour",
    ingredients: ["Burbon Whiskey", "Lemon Juice", "Simple Syrup"],
    image:
      "https://static.onecms.io/wp-content/uploads/sites/44/2020/04/16/7979588.jpg",
  },
  {
    name: "Manhattan",
    ingredients: ["Whiskey", "Vermouth", "Bitters"],
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2013/2/13/0/ED0309H_classic-manhattan-cocktail_s4x3.jpg.rend.hgtvcom.616.462.suffix/1371614573383.jpeg",
  },
  {
    name: "Mimosa",
    ingredients: ["champagne", "Orange Juice"],
    image:
      "https://craftybartending.com/wp-content/uploads/2018/04/Mimosa-cocktail.jpg",
  },
  {
    name: "Long Island Iced Tea",
    ingredients: [
      "Gin",
      "Rum",
      "Vodka",
      "Tequila",
      "Triple Sec",
      "Sour",
      "Coke",
    ],
    image:
      "https://www.long-island-iced-tea.com/images/16x9/long-island-iced-tea-16x9.jpg",
  },
  {
    name: "Tom Collins",
    ingredients: ["Gin", "Lemon Juice", "Soda Water"],
    image:
      "https://www.thespruceeats.com/thmb/-0IJqBDzM8D-2JJbXJSFpu66Vow=/3333x3333/smart/filters:no_upscale()/tom-collins-recipe-759833-hero-01-226aadb6d7a54008bd2fe44e2bad7710.jpg",
  },
  {
    name: "Amaretto Sour",
    ingredients: ["Amaretto", "Lemon Juice"],
    image:
      "https://www.bakingbeauty.net/wp-content/uploads/2020/02/amaretto-sour-e1582423017995.jpg",
  },
];

export default cocktails;
